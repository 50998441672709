<div class="form-section-header">
	<div class="container">
		<h3><em>Section 1 -</em> To be completed by/with person</h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Personal Information</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<div class="form-section-validator-controls w-32">
			<mat-form-field class="w-100">
				<mat-label>First Name</mat-label>
				<input matInput [(ngModel)]="caseFile.personalInfo.firstName" class="form-control-textbox"
					name="firstNamesection1" [disabled]="isDisabled" required />
				
		    </mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.personalInfo.firstName == ''" class="error">Please Include First Name</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.personalInfo.firstName == ''" class="error">Please Include First Name</mat-error>
		</div>
		<div class="form-section-validator-controls w-32">
			<mat-form-field class="w-100"> 
				<mat-label>Last Name</mat-label>
				<input matInput [(ngModel)]="caseFile.personalInfo.lastName" class="form-control-textbox"
					name="lastNamesection1" [disabled]="isDisabled" required />
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.personalInfo.lastName == ''" class="error">Please Include Last Name</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.personalInfo.lastName == ''" class="error">Please Include Last Name</mat-error>
            </div>

			<div class="form-section-validator-controls w-32">
			<mat-form-field class="w-100">
				<mat-label>Pronouns</mat-label>
				<mat-select placeholder="Select One" name="drpPronounssection1"
					[(ngModel)]="caseFile.personalInfo.pronounVersionId" required [disabled]="isDisabled">
					<mat-option *ngFor="let pronoun of pronouns" [value]="pronoun.id">{{ pronoun.pronoun}}</mat-option>
				</mat-select>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.personalInfo.pronounVersionId == null" class="error">Please Select Pronouns</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.personalInfo.pronounVersionId == null" class="error">Please Include Pronoun</mat-error>
            </div>
		</fieldset>

		<fieldset>
			<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Personal Email (optional)</mat-label>
				<input matInput [(ngModel)]="caseFile.personalInfo.email" class="form-control-textbox" name="emailsection1"
					placeholder="To receive meeting invitation" [disabled]="isDisabled"
					pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}" />
					<mat-error >Please Use a Correct Email Including “&#64;“</mat-error>	
			</mat-form-field>
			
		</div>
			<span class="phnspan W-32">
				<div class="form-section-validator-controls">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Personal Health Number (PHN)</mat-label>
				<input matInput [(ngModel)]="caseFile.personalInfo.personalHealthNumber" class="form-control-textbox"
					[disabled]="isDisabled || caseFile.personalInfo.personalHealthNumberNotApplicable" name="phnsection1"
					required />
				</mat-form-field>
				<mat-error *ngIf="parentSubmitted && !caseFile.personalInfo.personalHealthNumberNotApplicable && caseFile.personalInfo.personalHealthNumber == ''" class="error">
					Please Include PHN</mat-error>
			<mat-error *ngIf="ICSaveClicked && !caseFile.personalInfo.personalHealthNumberNotApplicable && caseFile.personalInfo.personalHealthNumber == ''" class="error">
				Please Include PHN</mat-error>
		</div>
			<span class="phncheckbox">
				<label>
				<input type="checkbox" name="chkPHNnasection1" [disabled]="isDisabled" [(ngModel)]="caseFile.personalInfo.personalHealthNumberNotApplicable">
				Not Applicable
			</label>
		</span>
		</span>
		<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Date of Birth</mat-label>
				<input matInput [matDatepicker]="dp3" [(ngModel)]="caseFile.personalInfo.dateOfBirth" name="dobsection1"
					[disabled]="isDisabled" [max]="maxDate" required placeholder="DD/MM/YYYY">
				
				<mat-datepicker-toggle matIconSuffix [for]="dp3" [disabled]="isDisabled"></mat-datepicker-toggle>
				<mat-datepicker #dp3 [disabled]="isDisabled" required startView="multi-year"></mat-datepicker>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.personalInfo.dateOfBirth == undefined" class="error">Please Select Birthdate</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.personalInfo.dateOfBirth == undefined" class="error">Please Select Birthdate</mat-error>
			</div>
		</fieldset>
		<fieldset>
		</fieldset>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Meeting Format</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset ngForm #formatForm="ngForm">
			<label id="intake-form-radio-group-label">Most meetings with Rights Advisors are by videoconference or
				phone.
				The facility/team will provide access to a private meeting space and communications equipment.<br> <br>

				In-person Rights Advice is available in some locations but may only be available during limited dates
				and hours.
				Priority for in-person service is given to people who may have difficulty communicating by
				videoconference or
				phone.
				A member of your care team can tell you if in-person service is available in your location. <br><br>

				I prefer to meet by:</label>
				<div class="form-section-validator-controls w-100">	
			<mat-radio-group name="radMeetingFormatGroup" [(ngModel)]="caseFile.meetingFormatVersionId"
				aria-labelledby="intake-form-radio-group-label" class="intake-form-radio-group display-vertical w-100"
				[disabled]="isDisabled" [ngClass]="{'raderror-class': parentSubmitted && formatForm.controls['radMeetingFormatGroup'].hasError('required')}"  required >
				<mat-radio-button name="radioMeetingFormatsection1" *ngFor="let meetingFormat of meetingFormats"
					[value]="meetingFormat.id" class="intake-form-radio-button" >
					{{meetingFormat.meetingFormatName}}
				</mat-radio-button>
				<br/>
				<mat-error	*ngIf="parentSubmitted && caseFile.meetingFormatVersionId == undefined" class="error">Please select one</mat-error>
					<mat-error *ngIf="ICSaveClicked && caseFile.meetingFormatVersionId == null" class="error">Please Select one</mat-error>
			</mat-radio-group>
			</div>
		</fieldset>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Meeting Attendees</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>

			<label id="intake-form-meeting-attendee-label">
				Rights Advice meetings are private but you can choose if you want someone to attend with you.
				This could be a family member, a friend, or any other support person. You can ask a facility staff member to help you to contact them.
				 Please check if the person(s) can attend the meeting before listing them below.
			</label>
			<div class="form-section-validator-controls w-100">	
			<mat-radio-group name="radMeetingAttendeesGroupsection1" aria-labelledby="intake-form-meeting-attendee-label"
				[disabled]="isDisabled" required class="intake-form-radio-group" [(ngModel)]="maradiobuttonvalue"
				(change)="radiobuttonMeetingAttendeeChange()">
				<!-- #meetingAttendees="ngModel" required -->
				<mat-radio-button name="radioMeetingAttendee1section1" class="intake-form-radio-button display-vertical"
					value="1">
					I choose to attend the meeting alone.
				</mat-radio-button>
				<mat-radio-button name="radioMeetingAttendee2section1" class="intake-form-radio-button" value="2">
					I choose to have the following person(s) attend the meeting with me. To help with scheduling, please include any notes on when they are available to meet.
				</mat-radio-button>
			</mat-radio-group>
			</div>
		</fieldset>

		<div [hidden]="meetingAttendeeFlag">
			<div class="designatedFacilityForm meeting-attendee-form">
				Person 1
				<div class="clear"></div>
				<span class="first-2-columns">
					<div class="form-section-validator-controls w-41-9">
					<mat-form-field class="w-100">
						<mat-label>Name</mat-label>
						<input matInput [(ngModel)]="maFirstName1" class="form-control-textbox" name="maFirstName1"							
							[disabled]="isDisabled" />
						
					</mat-form-field>
					<mat-error *ngIf="parentSubmitted && !meetingAttendeeFlag && (maFirstName1.trim() == '' && maRelationship1.trim() == '')" class="error">Please include either name or relation</mat-error>
					<mat-error *ngIf="ICSaveClicked && !meetingAttendeeFlag && (maFirstName1.trim() == '' && maRelationship1.trim() == '')" class="error">Please include either Name or relation</mat-error>
				</div>
					<span>and/or</span>
					<div class="form-section-validator-controls w-41-9">
					<mat-form-field class="w-100">
						<mat-label>Role/Relationship</mat-label>
						<input matInput [(ngModel)]="maRelationship1" class="form-control-textbox" name="maRelationship1"
							[disabled]="isDisabled" />
						
					</mat-form-field>
					<mat-error *ngIf="parentSubmitted && !meetingAttendeeFlag && (maFirstName1.trim() == '' && maRelationship1.trim() == '')" class="error">Please include either name or relation</mat-error>
					<mat-error *ngIf="ICSaveClicked && !meetingAttendeeFlag && (maFirstName1.trim() == '' && maRelationship1.trim() == '')" class="error">Please include either name or relation</mat-error>
				</div>
				</span>
				<mat-form-field>
					<mat-label>Email (optional)</mat-label>
					<input type="text" matInput [(ngModel)]="maEmail1" class="form-control-textbox" name="maEmail1"
						[disabled]="isDisabled" pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}" />
					<mat-hint>To receive meeting inviation</mat-hint>
					<mat-error>Please use a correct email with “&#64;“</mat-error>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Notes on Availability (optional)</mat-label>
					<input matInput [(ngModel)]="maNotes1" class="form-control-textbox" name="maPhoneNumber1"
						[disabled]="isDisabled" />
				</mat-form-field>
			</div>

			<div class="designatedFacilityForm meeting-attendee-form">
				Person 2
				<div class="clear"></div>
				<span class="first-2-columns">
					<mat-form-field class="nested-label">
						<mat-label>Name (optional)</mat-label>
						<input matInput [(ngModel)]="maFirstName2" class="form-control-textbox" name="maFirstName2"
							[disabled]="isDisabled" />
					</mat-form-field>
					<span>and/or</span>
					<mat-form-field class="nested-label">
						<mat-label>Role/Relationship (optional)</mat-label>
						<input matInput [(ngModel)]="maRelationship2" class="form-control-textbox" name="maLastName2"
							[disabled]="isDisabled" />
					</mat-form-field>
				</span>
				<mat-form-field>
					<mat-label>Email (optional)</mat-label>
					<input matInput [(ngModel)]="maEmail2" class="form-control-textbox" name="maEmail2"
						[disabled]="isDisabled" pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}" />
					<mat-hint>To receive meeting inviation</mat-hint>
					<mat-error>Please use a correct email with “&#64;“</mat-error>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Notes on Availability (optional)</mat-label>
					<input matInput [(ngModel)]="maNotes2" class="form-control-textbox" name="maPhoneNumber2"
						[disabled]="isDisabled" />
				</mat-form-field>
			</div>

		</div>
	</div>
</div>

<div class="form-subsection-header">
	<div class="container">
		<h4>Additional Support</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Do you need interpretation services?</mat-label>
				<mat-select placeholder="Select One" name="drpInterpretationServicesection1"
					(selectionChange)="getInterpretationServicesAnswer()" [disabled]="isDisabled"
					[(ngModel)]="caseFile.interpretationServicesVersionId" name="interpretationservicesection1" required>
					<mat-option *ngFor="let is of interpretationServices" [value]="is.id">{{
						is.interpretationServiceOption }}</mat-option>
				</mat-select>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.interpretationLanguageVersionID == null" class="error">Please select one</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.interpretationLanguageVersionID == null" class="error">Please Select one</mat-error>
			</div>

			<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>If yes, please select your language</mat-label>
				<mat-select name="drpInterpretationLanguage" [(ngModel)]="caseFile.interpretationLanguageVersionID"
					placeholder="Select One" [disabled]="!interpretationLanguageCondition || isDisabled"
					[required]="interpretationLanguageCondition">
					<mat-option *ngFor="let il of interpretationLanguages" [value]="il.id">{{ il.interpretationLanguage
						}}</mat-option>
				</mat-select>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.interpretationLanguageVersionID == null" class="error">Please Select one</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.interpretationLanguageVersionID == null" class="error">Please Select one</mat-error>
			</div>

			<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Do you need hearing services?</mat-label>
				<mat-select name="drpHearingServicessection1" [(ngModel)]="caseFile.hearingServicesVersionId"
					placeholder="Select One" [disabled]="isDisabled" required>
					<mat-option *ngFor="let hs of hearingServices" [value]="hs.id">{{ hs.hearingServicesOption
						}}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.hearingServicesVersionId == null" class="error">Please Select one</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.hearingServicesVersionId == null" class="error">Please Select one</mat-error>
			</div>
		</fieldset>

		<fieldset>
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Do you have any other requests or communication needs? (optional)</mat-label>
				<textarea matInput [(ngModel)]="caseFile.anyOtherRequest" class="form-control-textbox"
					name="anyothercommneedssection1" [disabled]="isDisabled" ></textarea>
			</mat-form-field>
		</fieldset>

	</div>
</div>