import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { format } from "date-fns";
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section1',
	templateUrl: './section1.component.html',
	styleUrls: ['./section1.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section1Component implements OnInit, AfterViewInit {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() submittedErrors: any;
	@Input() parentSubmitted: boolean = false;
	@Input() ICSaveClicked:boolean = false;

	maxDate = new Date(); //format(new Date(), "dd/MM/yyyy");

	meetingFormats: any[] = [];
	interpretationServices: any[] = [];
	interpretationLanguages: any[] = [];
	hearingServices: any[] = [];
	interpretationLanguageCondition: boolean = false;
	meetingAttendees: any[] = [];
	maradiobuttonvalue: string = '1';
	meetingAttendeeFlag: boolean = true;
	pronouns: any[] = [];

	defaultLanguageVersionId: number = 0;
	defaultHearingServiceId: number = 0;

	inPersonMeetingFormatVersionId: number = 3;
	inPersonMeetingFormatSelected: boolean = false;

	maId1: number = 0;
	maFirstName1: string = '';
	maRelationship1: string = '';
	maEmail1: string = '';
	maNotes1: string = '';

	maId2: number = 0;
	maFirstName2: string = '';
	maRelationship2: string = '';
	maEmail2: string = '';
	maNotes2: string = '';


	constructor(
		private casefileoptionService: CaseFileOptionService
	) { }


	ngOnInit(): void {
		this.casefileoptionService.getMeetingFormats().subscribe({
			next: (meetingformats) => {
				this.meetingFormats = meetingformats;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => { }
		});

		this.casefileoptionService.getInterpretationService().subscribe({
			next: (interpretationServices) => {
				this.interpretationServices = interpretationServices;
				this.loadDefaultInterpretationService();
				this.loadHearingServices();
				this.loadInterpretationLanguages();
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => { }
		});

		this.casefileoptionService.getPronouns().subscribe({
			next: (pronouns) => {
				this.pronouns = pronouns;
			},
		});
	}

	ngAfterViewInit(): void {
		if (this.caseFile.meetingAttendees.length > 0) {
			this.maradiobuttonvalue = '2';
			this.meetingAttendeeFlag = false;
		}
	}

	hasError(errorKey: string): boolean {
		if (this.submittedErrors == undefined || this.submittedErrors.length == 0)
			return false;

		let error = this.submittedErrors[errorKey];
		if (error !== undefined)
			return true;

		return false;
	}

	getInterpretationServicesAnswer(): void {
		if (this.caseFile.interpretationServicesVersionId == 1) {
			this.interpretationLanguageCondition = true;
		} else {
			this.interpretationLanguageCondition = false;
			this.caseFile.interpretationLanguageVersionID = this.defaultLanguageVersionId;
		}
	}
	
	meetingFormatVersionChange(): void {
		this.inPersonMeetingFormatSelected = false;
	}

	inPersonMeetingFormatChange(): void {
		this.caseFile.meetingFormatVersionId = this.inPersonMeetingFormatVersionId;
	}

	radiobuttonMeetingAttendeeChange(): void {
		if (this.maradiobuttonvalue == '2') {
			this.meetingAttendeeFlag = false;
		} else {
			this.meetingAttendeeFlag = true;
		}
	}

	loadDefaultInterpretationService() {
		// This isn't firing correctly since the casefile isn't loaded yet from intake-form

		// const defaultValue = this.interpretationServices.find(is => is.interpretationServiceOption == "No");
		// if (defaultValue !== undefined && this.caseFile.interpretationServicesVersionId === undefined) {
		//   this.caseFile.interpretationServicesVersionId = defaultValue;
		// }
	}

	loadInterpretationLanguages(): void {
		this.casefileoptionService.getInterpretationLanguage().subscribe({
			next: (languages) => {
				this.interpretationLanguages = languages;
				const english = languages.find(
					(language) => language.interpretationLanguage == "English"
				);
				this.defaultLanguageVersionId = english !== undefined ? english.id : 0;
				this.getInterpretationServicesAnswer();
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => { },
		});
	}

	loadHearingServices(): void {
		this.casefileoptionService.getHearingServices().subscribe({
			next: (hearingservices) => {
				this.hearingServices = hearingservices;
				const none = hearingservices.find(
					(hearingService) => hearingService.hearingServicesOption == "None"
				);
				this.defaultHearingServiceId = none !== undefined ? none.id : 0;
				this.caseFile.hearingServicesVersionId= this.caseFile.hearingServicesVersionId == undefined||0?this.defaultHearingServiceId:this.caseFile.hearingServicesVersionId;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => { }
		});
	}

	validateSection1():boolean
	{
		let flag=true;
		if(this.caseFile.personalInfo.firstName == '')
		{
			flag=false
		}
		if(this.caseFile.personalInfo.lastName == '')
		{
			flag=false
		}
		if(this.caseFile.personalInfo.pronounVersionId == null)
		{
			flag=false
		}
		if(this.caseFile.personalInfo.dateOfBirth == undefined)
		{
			flag=false
		}
		if(!this.caseFile.personalInfo.personalHealthNumberNotApplicable && this.caseFile.personalInfo.personalHealthNumber == '')
		{
			flag=false
		}
		if(this.caseFile.meetingFormatVersionId == null)
		{
			flag=false
		}
		if(!this.meetingAttendeeFlag && (this.maFirstName1 == ''&& this.maRelationship1 == '') )
		{
			flag=false
		}
		
		return flag;
	}
}
