<div class="clear"></div>
<div class="caseFileTableContainer allFilesContainer">
	<div class="filterForm">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Case ID</mat-label>
				<input matInput class="form-control-textbox" name="filtercaseid" placeholder="Case ID"
					[(ngModel)]="caseFileQueryFilter.CaseFileTerm" />

			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Name</mat-label>
				<input matInput class="form-control-textbox" name="filternames" placeholder="First and/or last name"
					[(ngModel)]="caseFileQueryFilter.NameTerm">
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Personal Health Number</mat-label>
				<input matInput class="form-control-textbox" name="filterphn" placeholder="Personal Health Number"
					[(ngModel)]="caseFileQueryFilter.PhnTerm">
			</mat-form-field>
		</fieldset>

		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Rights Advisor</mat-label>
				<mat-select placeholder="Select" name="filterdrpRA"
					[(ngModel)]="caseFileQueryFilter.RightsAdvisorVersionId">
					<mat-option value="0">Select</mat-option>
					<mat-option *ngFor="let ra of raList" [value]="ra.rightsAdvisorVersionId">{{
						ra.advisorName}}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always" class="w-65-5">
				<mat-label>Facility (Unit)</mat-label>
				<mat-select placeholder="Select" name="filterFacilityUnit"
					[(ngModel)]="caseFileQueryFilter.FacilityUnitVersionId">
					<mat-option *ngFor="let ful of facilityUnitList"
						[value]="ful.unitVersionId">{{ful.facilityName}}-{{ful.unitName}}</mat-option>
				</mat-select>
			</mat-form-field>
		</fieldset>

		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Start Date</mat-label>
				<input matInput [matDatepicker]="filterStartDate" class="form-control-textbox" name="filterStartDate"
					placeholder="Start Date" [(ngModel)]="caseFileQueryFilter.StartDate" />
				<mat-datepicker-toggle matIconSuffix [for]="filterStartDate"></mat-datepicker-toggle>
				<mat-datepicker #filterStartDate disabled="false"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>End Date</mat-label>
				<input matInput [matDatepicker]="filterEndDate" class="form-control-textbox" name="filterEndDate"
					placeholder="End Date" [(ngModel)]="caseFileQueryFilter.EndDate">
				<mat-datepicker-toggle matIconSuffix [for]="filterEndDate"></mat-datepicker-toggle>
				<mat-datepicker #filterEndDate disabled="false"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Status</mat-label>
				<mat-select placeholder="Select One" name="filterStatus"
					[(ngModel)]="caseFileQueryFilter.CaseFileStatus">
					<mat-option *ngFor="let status of applicationStatus"
						[value]="status">{{enumDescService.getDescriptionForFilters(status,usertype)}}</mat-option>
				</mat-select>
			</mat-form-field>
		</fieldset>

		<div class="form-buttons">
			<div class="filterButtons">
				<button class="submit-form-button" (click)="searchWithFilters()" #searchButton>Search</button>
				<button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
			</div>
		</div>
	</div>

	<div class="clear"></div>
	<div class="tableContents">
		<ng-container #allFilesTable
			*ngTemplateOutlet=" caseFileTable; context: { data: caseFiles, headerVisible: true }"></ng-container>
		<mat-paginator #paginator [length]="this.totalRecords" [pageSize]="25" [hidden]="this.totalRecords === 0"
			[pageSizeOptions]="[10, 25, 50]" (page)="handlePageEvent($event)" ariaLabel=''></mat-paginator>
	</div>
</div>

<ng-template #caseFileTable let-data="data" let-headerVisible="headerVisible">
	<app-no-results #noResults></app-no-results>

	<table mat-table class="fileList primary" [dataSource]="data" matSort [hidden]="data.length === 0"
		matSortActive="CMHADateRequestReceived" matSortDirection="desc" (matSortChange)="announceSortChange($event)"
		>

		<ng-container matColumnDef="Id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="roundedLeft"
				sortActionDescription="Sort by Case ID">
				Case ID
			</th>
			<td mat-cell *matCellDef="let caseFile">
				<a routerLink="/treatment-form/view/{{ caseFile.id }}">{{ caseFile.id }}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="LastName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="roundedLeft"
				sortActionDescription="Sort by Name">
				Name
			</th>
			<td mat-cell *matCellDef="let caseFile">
				<a routerLink="/treatment-form/view/{{ caseFile.id }}">{{ caseFile.lastName }}, {{ caseFile.firstName
					}}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="CMHADateRequestReceived">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="roundedLeft"
				sortActionDescription="Sort by Date">
				Date Received
			</th>
			<td mat-cell *matCellDef="let caseFile" >
				<a routerLink="/treatment-form/view/{{ caseFile.id }}">{{
					caseFile.updatedOnDateTimeUTC | date : "MMM dd, yyyy"
					}}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="FacilityUnitVersionId">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Facility - Unit">
				<span class="facility-name">Facility</span><br />
				<span class="facility-unit-name">Unit</span><br />
			</th>
			<td mat-cell *matCellDef="let caseFile">
				<a routerLink="/treatment-form/view/{{ caseFile.id }}">
					<span class="facility-name">{{ caseFile.facilityMentalHealthProgramName }}</span><br>
					<span class="facility-unit-name">{{ caseFile.facilityUnitName }}</span>
				</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="CaseFileStatus">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="roundedLeft"
				sortActionDescription="Sort by Status">
				Status
			</th>
			<td mat-cell *matCellDef="let caseFile" [ngClass]="{'diff-user-file':(loggedInRA != caseFile.rightsAdvisorUserId && 
			setCaseFileStatus(caseFile.status) == 'RA Assigned' && userRoleName=='RA')}">
				<div >
				<a routerLink="/treatment-form/view/{{ caseFile.id }}">
					
					<app-status-bubble [status]="setCaseFileStatus(caseFile.status)"></app-status-bubble>
				</a>
			</div>
			</td>
		</ng-container>

		<ng-container *ngIf="headerVisible">
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
		</ng-container>
		<tr mat-row *matRowDef="let caseFile; columns: columnsToDisplay"></tr>
	</table>
</ng-template>
<div class="clear"></div>