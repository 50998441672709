<div class="form-section-header">
	<div class="container">
		<h3><em>Section 2 -</em> To be completed by Facility/Team</h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Information</h4>
	</div>
</div>
<div class="information-board">
	<div class="container">
		<div class="warning-sign"><img src="/assets/normal_u130.svg" height="34px" width="37px"></div>
		<div class="warning-text"> This form must be submitted as soon as possible after the request for Rights Advice is made.
		</div>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<div class="form-section-validator-controls w-48">
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Facility or Community Mental Health Program</mat-label>
				<mat-select placeholder="Select One" name="drpFacilitysection2" [(ngModel)]="facilityid"
					(selectionChange)="drpfacilitychange()" [disabled]="isDisabled||disablefordf" required="">
					<mat-option *ngFor="let fl of facilityList" [value]="fl.designatedFacilityVersionId">{{
						fl.facilityName
						}}</mat-option>
				</mat-select>
				</mat-form-field>
				<mat-error *ngIf="parentSubmitted && facilityid == 0" class="error">Please Select Facility</mat-error>
				<mat-error *ngIf="ICSaveClicked && facilityid == 0" class="error">Please Select Facility</mat-error>
			</div>

			<div class="form-section-validator-controls w-48">
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Unit</mat-label>
				<mat-select placeholder="Select One" name="drpUnitssection2" [(ngModel)]="caseFile.unitVersionId"
					(selectionChange)="setUnitData()" [disabled]="isDisabled||disablefordf" required>
					<mat-option *ngFor="let unit of unitObj" [value]="unit.unitVersionId">{{ unit.unitName
						}}</mat-option>
				</mat-select>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.unitVersionId == undefined" class="error">Please Select Unit</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.unitVersionId == undefined" class="error">Please Select Unit</mat-error>
			</div>
		</fieldset>
		<fieldset>
			<div class="form-section-validator-controls w-48">
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Address</mat-label>
				<input matInput class="form-control-textbox" name="unitaddresssection2" [value]="unitaddress"
					[disabled]="isDisabled||disablefordf" required/>
     		</mat-form-field>
			 <mat-error *ngIf="parentSubmitted && unitaddress == ''" class="error">Please include address</mat-error>
			 <mat-error *ngIf="ICSaveClicked && unitaddress == ''" class="error">Please include address</mat-error>
			</div>
			 
			<div class="form-section-validator-controls w-48">
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>City/Town</mat-label>
				<input matInput class="form-control-textbox" name="unitcitysection2" [value]="facilityCity"
					[disabled]="isDisabled||disablefordf" required/>
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && facilityCity == ''" class="error">Please Include Unit City/Town</mat-error>
			<mat-error *ngIf="ICSaveClicked && facilityCity == ''" class="error">Please Include Unit City/Town</mat-error>
			</div>
		</fieldset>

		<fieldset>
			<div class="form-section-validator-controls w-32">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Phone</mat-label>
				<input matInput class="form-control-textbox" name="unitPhonesection2" [(ngModel)]="caseFile.unitPhone"
					[disabled]="isDisabled" required/>
				</mat-form-field>
				<mat-error *ngIf="parentSubmitted && caseFile.unitPhone == ''" class="error">Please Include facility Phone</mat-error>
				<mat-error *ngIf="ICSaveClicked && caseFile.unitPhone == ''" class="error">Please Include facility Phone</mat-error>
				</div>

				<div class="form-section-validator-controls w-32">	
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Fax (optional)</mat-label>
				<input matInput class="form-control-textbox" name="unitFaxsection2" [(ngModel)]="caseFile.unitFax"
					[disabled]="isDisabled"/>
				</mat-form-field>
				
				</div>

				<div class="form-section-validator-controls w-32">	
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Email</mat-label>
				<input matInput class="form-control-textbox" name="unitEmailsection2" [(ngModel)]="caseFile.unitEmail"
					[disabled]="isDisabled" pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}" required/>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.unitEmail==''" class="error">Please Include valid facility contact email</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.unitEmail==''" class="error">Please Include valid facility contact email</mat-error>
			</div>
		</fieldset>
		<fieldset>
			<div class="form-section-validator-controls w-32">	
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Name/Position of primary contact person</mat-label>
				<input matInput class="form-control-textbox" placeholder="Can assist in scheduling the meeting"
					name="primarycontactpersonsection2" [(ngModel)]="caseFile.primaryContactPersonName" [disabled]="isDisabled"
					required />
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.primaryContactPersonName == ''" class="error">Please Include facility primary contact</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.primaryContactPersonName == ''" class="error">Please Include facility primary contact</mat-error>
			</div>

			<div class="form-section-validator-controls w-32">	
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Length of current certificate</mat-label>
				<mat-select placeholder="Select One" name="drpHearingServicessection2"
					[(ngModel)]="caseFile.certificateLengthVersionId" [disabled]="isDisabled" required>
					<mat-option *ngFor="let cl of certificateLengths" [value]="cl.id">{{ cl.certificateLengthName
						}}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.certificateLengthVersionId == undefined" class="error">Please Include length of certificate</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.certificateLengthVersionId == undefined" class="error">Please Include length of certificate</mat-error>
		</div>

			<div class="form-section-validator-controls w-32">	
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Date of Involuntary Admission or Admission by Parent/Guardian</mat-label>
				<input matInput [matDatepicker]="dp4" [(ngModel)]="caseFile.dateOfInvoluntaryAdmission" name="doiasection2"
					[disabled]="isDisabled" [max]="maxDate" placeholder="DD/MM/YYYY" required>
				<mat-hint>DD/MM/YYYY</mat-hint>
				<mat-datepicker-toggle matIconSuffix [for]="dp4" [disabled]="isDisabled"></mat-datepicker-toggle>
				<mat-datepicker #dp4 [disabled]="isDisabled"></mat-datepicker>
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.dateOfInvoluntaryAdmission == undefined" class="error">Please Include date of Involuntary Admission</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.dateOfInvoluntaryAdmission == undefined" class="error">Please Include date of Involuntary Admission</mat-error>
			</div>
		</fieldset>

		<span>Patient Status </span>
		<fieldset ngForm #statusForm="ngForm">
			<div class="form-section-validator-controls w-100" >	
				<mat-radio-group name="radpatientstatusIPsection2" class="intake-form-radio-group patient-status-rad-group w-100"
				[(ngModel)]="caseFile.patientAdmitRequestVersionId" [disabled]="isDisabled" required>
				<div class="display-vertical"><label id="rad-patient-status-IP">Involuntary Patient</label><br>
					<mat-radio-button name="radioMeetingFormat" class="intake-form-radio-button"
						aria-labelledby="rad-patient-status-IP" *ngFor="let para16 of patientAdmitRequestAbove16"
						[value]="para16.id">
						{{para16.patientAdmitRequest}}
					</mat-radio-button>
				</div>
				<div class="display-vertical">
					<label id="rad-patient-status-U16">Patient under age 16 admitted on request of parent or
						guardian</label><br>
					<mat-radio-button name="radioMeetingFormatsection2" class="intake-form-radio-button"
						aria-labelledby="rad-patient-status-U16" *ngFor="let paru16 of patientAdmitRequestUnder16"
						[value]="paru16.id">
						{{paru16.patientAdmitRequest}}
					</mat-radio-button>
				</div>
				
			</mat-radio-group>
			<div class="clear"></div>
			<mat-error *ngIf="parentSubmitted && caseFile.patientAdmitRequestVersionId == undefined" class="error">Please
				select one</mat-error>
				<mat-error *ngIf="ICSaveClicked && caseFile.patientAdmitRequestVersionId == undefined" class="error">Please
					select one</mat-error>
				</div>
		</fieldset>
		<fieldset>
			<div class="form-section-validator-controls w-100" >	
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Notes for Scheduling a Meeting</mat-label>
				<textarea matInput class="form-control-textbox w-100" name="facilitynotessection2"
					[(ngModel)]="caseFile.facilityNotes" [disabled]="isDisabled" required=""></textarea>
				
			</mat-form-field>
			<mat-error *ngIf="parentSubmitted && caseFile.facilityNotes == ''">Please Include notes for scheduling a meeting</mat-error>
			<mat-error *ngIf="ICSaveClicked && caseFile.facilityNotes == ''">Please Include notes for scheduling a meeting</mat-error>
			</div>
		</fieldset>
	</div>
</div>